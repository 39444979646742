<template>
  <b-form-radio-group
    class="checkout-new-user-addresses"
    v-model="selected"
    stacked
    v-bind="$attrs"
  >
    <div v-if="basketIs.loading" class="user-address">
      <loader variant="primary" inline/>
    </div>

    <template v-else>
      <div class="user-address radio-centered" v-for="userAddress in userAddresses" :key="userAddress.id">
        <b-form-radio :value="userAddress.key">
          <c-checkout-user-address :address-id="userAddress.key"/>
        </b-form-radio>
      </div>

      <div class="user-address">
        <b-form-radio :value="addUserAddressOption.value">
          <div class="address-add"><b-icon icon="add" scale="0.85"/> {{ $t(`${tPath}.adduseraddress.label`) }}</div>

          <entity-form
            v-show="addUserAddressSelected"
            :id="addUserAddressOption.formId"
            entityKey="useraddresses"
            :entityValues="value || {}"
            :showButtons="true"
            class="address-add-form"
            :buttonVariants="{ submit: 'outline-primary' }"
            :buttonSizes="{ submit: 'sm' }"
            @form:submit="addUserAddress"
          >
            <template #submit>
              {{ $t(`${tPath}.adduseraddress.savebutton`) }}
            </template>
          </entity-form>
        </b-form-radio>
      </div>
    </template>
  </b-form-radio-group>
</template>

<script>
import { USER_ADDRESS_TYPES } from '@/constants'

import Loader from '@/components/Loader'
import EntityForm from '@/components/authenticated/Entity/AddForm'
import CheckoutOptionForm from '@/components/authenticated/Order/CheckoutOptionForm'

import { getNamedContext } from '@/assets/js/helper/vm'

const ALLOWED_USERADDRESS_TYPES = Object.values(USER_ADDRESS_TYPES).map(t => t.type)
const USERADDRESS_PROPERTY_KEYS = Object.values(USER_ADDRESS_TYPES).map(t => t.checkoutproperties.key)

export default {
  name: 'CheckoutNewUserAddresses',
  components: {
    Loader,
    EntityForm
  },
  props: {
    value: {
      required: true
    },
    userAddressType: {
      type: String,
      default: USER_ADDRESS_TYPES.default.type,
      validator: value => ALLOWED_USERADDRESS_TYPES.includes(value)
    },
    addOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: USERADDRESS_PROPERTY_KEYS[0],
      addUserAddressOption: {
        value: 'AddUserAddress',
        formId: 'Form_CheckoutAddNewUserAddress'
      }
    }
  },
  computed: {
    basketIs () {
      return this.$store.getters['shoppingcart/is']
    },
    basketConfiguration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    },
    addressTypeConfig () {
      const ADDRESSTYPE_CHECKOUTPROPERTIES = Object.values(USER_ADDRESS_TYPES).map(t => Object.assign({ type: t.type }, t.checkoutproperties || {}))
      const properties = ADDRESSTYPE_CHECKOUTPROPERTIES.find(p => p.type === this.userAddressType)

      const inheritedProperties = [properties]
      let iKey = properties.inheritKey

      while (iKey) {
        const props = ADDRESSTYPE_CHECKOUTPROPERTIES.find(p => p.key === iKey) || {}
        if (props.key) inheritedProperties.push(props)
        iKey = props.inheritKey
      }

      const inheritedAddressKey = (inheritedProperties.find(p => !this.basketConfiguration[p.useBaseKeyBoolean]) || {}).key

      return Object.assign({}, properties, { inheritedAddressKey })
    },
    user () {
      return this.basketConfiguration.newUser || {}
    },
    userAddresses () {
      return this.addOnly ? [] : USERADDRESS_PROPERTY_KEYS
        .reduce((addresses, tKey) => addresses.concat(this.basketConfiguration[tKey] ? Object.assign({ key: tKey }, this.basketConfiguration[tKey]) : []), [])
        .filter(a => a.key === this.addressTypeConfig.inheritKey)
    },
    addUserAddressSelected () {
      return this.selected === this.addUserAddressOption.value
    }
  },
  methods: {
    addUserAddress (formData) {
      if (formData.isValid) this.$emit('input', formData.controls)
    }
  },
  beforeCreate () {
    this.optionformContext = getNamedContext(CheckoutOptionForm.name, this)
  },
  watch: {
    basketConfiguration: {
      immediate: true,
      deep: true,
      handler () {
        if (this.basketConfiguration[this.addressTypeConfig.useBaseKeyBoolean] !== undefined) {
          if (this.basketConfiguration[this.addressTypeConfig.useBaseKeyBoolean]) {
            this.selected = this.addressTypeConfig.inheritKey
          } else {
            this.selected = this.addUserAddressOption.value
          }
        }
      }
    },
    selected () {
      this.optionformContext.$emit('control:change', {
        key: this.addressTypeConfig.useBaseKeyBoolean,
        value: this.selected !== this.addUserAddressOption.value
      })
    }
  }
}
</script>

<style lang="scss">
$checkoutnewuseraddresses-address-gap: $spacer * 0.25 !default;
$checkoutnewuseraddresses-address-padding-y: $spacer * 0.75 !default;
$checkoutnewuseraddresses-address-padding-x: $checkoutnewuseraddresses-address-padding-y !default;
$checkoutnewuseraddresses-address-bg: $light !default;
$checkoutnewuseraddresses-address-border-radius: $border-radius !default;
$checkoutnewuseraddresses-address-line-height: 1.2 !default;

$checkoutnewuseraddresses-address-add-color: $link-color !default;
$checkoutnewuseraddresses-address-add-text-decoration: $link-decoration !default;

$checkoutnewuseraddresses-address-form-gap: $spacer !default;

.checkout-new-user-addresses {
  .user-address {
    padding: $checkoutnewuseraddresses-address-padding-y $checkoutnewuseraddresses-address-padding-x;
    background-color: $checkoutnewuseraddresses-address-bg;
    border-radius: $checkoutnewuseraddresses-address-border-radius;
    line-height: $checkoutnewuseraddresses-address-line-height;

    .custom-radio {
      min-height: $checkoutnewuseraddresses-address-line-height * 1rem;

      .custom-control-input {
        ~ .custom-control-label {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }

    &.radio-centered {
      .custom-radio {
        .custom-control-input {
          ~ .custom-control-label {
            margin: 0;

            &:before,
            &:after {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .address-add {
      color: $checkoutnewuseraddresses-address-add-color;
      text-decoration: $checkoutnewuseraddresses-address-add-text-decoration;
    }

    .address-add-form {
      margin-top: $checkoutnewuseraddresses-address-form-gap;
    }

    + .user-address {
      margin-top: $checkoutnewuseraddresses-address-gap;
    }
  }
}
</style>
